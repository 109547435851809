<template>
  <v-dialog persistent scrollable v-model="dialog" max-width="640">
    <v-card>
      <v-card-title>
        <span class='text-h5'>{{ actionName }}</span>
      </v-card-title>
      <v-card-text class='pb-0'>
        <v-container
          style="max-height: 800px"
        >
          <v-form ref='form' v-model='valid' lazy-validation :disabled='loading'>
            <v-row>
              <v-col cols='12' class='py-0' v-if='action === "edit"'>
                <p class='mb-0 text--primary'>
                  アカウントステータス
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                  v-if='member.status'
                >
                  アカウントを無効にすると、ログインできなくなります。
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                  v-else
                >
                  アカウントを有効にすると、再度ログインできるようになります。
                </p>
                <v-switch
                  v-model='member.status'
                  :label="member.status ? '有効' : '無効'"
                ></v-switch>
              </v-col>
              <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  メールアドレス
                  <span class='required-label' v-if='action === "add"'>
                    必須
                  </span>
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  ログイン時に利用するメールアドレスです。必ずメールを受信できるアドレスを指定してください。また、メールアドレスは後から変更できません。
                </p>
                <v-text-field
                  v-model='member.email'
                  outlined
                  dense
                  type='email'
                  validate-on-blur
                  :disabled='action === "edit"'
                  :rules='[formRules.required, formRules.email]'
                ></v-text-field>
              </v-col>
              <v-col cols='12' class='py-0' v-if='action === "add"'>
                <p class='mb-0 text--primary'>
                  パスワード
                  <span class='required-label'>
                    必須
                  </span>
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  8 文字以上 16 文字以内で指定してください。半角数字、半角英字をそれぞれ 1 文字以上含める必要があります。
                </p>
                <v-text-field
                  v-model='member.password'
                  outlined
                  dense
                  validate-on-blur
                  v-bind:type="showPassword ? 'text' : 'password'"
                  v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append='showPassword = !showPassword'
                  :rules='[formRules.required, formRules.password]'
                ></v-text-field>
              </v-col>
              <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  会費ペイID
                  <span class='required-label'>
                    必須
                  </span>
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  会費ペイの管理IDを入力してください。決済状況との照合に利用します。
                </p>
                <v-text-field
                  v-model='member.payid'
                  outlined
                  dense
                  validate-on-blur
                  :rules='[formRules.required]'
                ></v-text-field>
              </v-col>
              <v-col cols='12' class='py-0' v-if='action === "edit"'>
                <p class='mb-0 text--primary'>
                  入会状況
                  <span class='required-label'>
                    必須
                  </span>
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  会員の入会状況を管理します。「退会」「休会」を選択すると「アカウントステータス」が「無効」となりログインできなくなります。
                </p>
                <v-radio-group row v-model='member.membership'>
                  <v-radio
                    label='入会中'
                    value='入会中'
                  ></v-radio>
                  <v-radio
                    label='休会'
                    value='休会'
                  ></v-radio>
                  <v-radio
                    label='退会'
                    value='退会'
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  会員名
                  <span class='required-label'>
                    必須
                  </span>
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  フリガナは全角カタカナで入力してください。
                </p>
                <v-row>
                  <v-col cols='12' class='d-flex justify-space-between pb-0'>
                    <v-text-field
                      v-model='member.lastName'
                      outlined
                      dense
                      validate-on-blur
                      placeholder='姓：山田'
                      :rules='[formRules.required]'
                    ></v-text-field>
                    <p class='mx-2'></p>
                    <v-text-field
                      v-model='member.firstName'
                      outlined
                      dense
                      validate-on-blur
                      placeholder='名：太郎'
                      :rules='[formRules.required]'
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' class='d-flex justify-space-between pt-0'>
                    <v-text-field
                      v-model='member.lastNameKana'
                      outlined
                      dense
                      validate-on-blur
                      placeholder='セイ：ヤマダ'
                      :rules='[formRules.required, formRules.kana]'
                    ></v-text-field>
                    <p class='mx-2'></p>
                    <v-text-field
                      v-model='member.firstNameKana'
                      outlined
                      dense
                      validate-on-blur
                      placeholder='メイ：タロウ'
                      :rules='[formRules.required, formRules.kana]'
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  レフティ
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  レフティ打席のみ予約可能となります。
                </p>
                <v-checkbox
                  v-model='member.lefty'
                  label='レフティとして登録します。'
                  class='text--secondary  v-messages'
                ></v-checkbox>
              </v-col>
              <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  所属店舗
                  <span class='required-label'>
                    必須
                  </span>
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  複数選択可能です。
                </p>
                <v-select
                  v-model='member.shops'
                  item-text='name'
                  item-value='id'
                  :items=shops
                  :menu-props='{ top: true, offsetY: true }'
                  outlined
                  chips
                  deletable-chips
                  multiple
                  required
                  :rules='[formRules.requiredArray]'
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-tex class='ma-3'>
        <v-alert
          type='warning'
          icon='mdi-alert-outline'
          text
          dense
          border='left'
          v-if='action === "edit"'
        >
        シフトが登録済みの日付は変更が適用されません。
        </v-alert>
      </v-card-tex>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color='grey darken-1' dark outlined @click='close' :disabled='loading'>
          <v-icon class='pr-1'>mdi-close</v-icon>
          閉じる
        </v-btn>
        <v-btn color='blue darken-2' dark outlined @click='save' :loading='loading'>
          <v-icon class='pr-1'>mdi-content-save-outline</v-icon>
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import util from '../util';

export default {
  name: 'member-dialog',
  props: {
    selectMember: {
      type: Object,
      default() {
        return {};
      },
    },
    action: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    users: [],
    shops: [],
    shiftRangeItems: [],
    showPassword: false,
    shopNameList: [],
    editedIndex: -1,
    member: {
      roll: 'member',
    },
    valid: true,
    // 入力規則
    formRules: {
      required: value => !!value || '必ず入力してください。',
      requiredArray: value => (value || '').length > 0 || '1つ以上選択してください。',
      limit_length: value => (value || '').length <= 0 || '20文字以内で入力してください。',
      password: value => /^(?=.*\d)((?=.*[a-z])|(?=.*[A-Z]))[0-9a-zA-Z]{8,16}$/.test(value) || '半角数字と半角英字の2種類を含む8文字以上16文字以内で入力してください。',
      email: value => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || 'メールアドレスの形式が正しくありません。',
      tel: value => /^(0\d{1,4}-\d{1,4}-\d{3,4})?$/.test(value) || '電話番号の形式が正しくありません。',
      kana: value => /^[ア-ン゛゜ァ-ォャ-ョー「」、]+$/.test(value) || '全角カタカナで入力してください。',
    },
    selectUser: {},
    loading: false,
  }),

  computed: {
    userInfo() {
      return this.$store.getters.user;
    },
    actionName() {
      return this.action === 'add' ? '追加' : '編集';
    },
  },

  watch: {
    async dialog() {
      this.$emit('input', this.dialog);
    },
    async value() {
      if (this.value) {
        this.member = Object.assign({}, this.member, this.selectMember);
        await this.reload();
        this.shiftRangeItems = util.timeList(0, 23);
      } else {
        this.member = {
          status: true,
          email: '',
          password: '',
          firstName: '',
          firstNameKana: '',
          lastName: '',
          lastNameKana: '',
          shops: [],
          weekdayShift: [],
          holidayShift: [],
          roll: 'member',
          lefty: false,
        };
      }
      this.dialog = this.value;
    },
    'member.name'(val) {
      if (!val) return;
      const name = val.split(' ');
      this.member.lastName = name[0];
      this.member.firstName = name[1];
    },
    'member.nameKana'(val) {
      if (!val) return;
      const nameKana = val.split(' ');
      this.member.lastNameKana = nameKana[0];
      this.member.firstNameKana = nameKana[1];
    },
    'member.membership'(val) {
      if (!val) return;
      this.member.status = val === '入会中';
    },
  },

  methods: {
    karteOpen(user) {
      this.selectUser = user;
      this.karteDialog = true;
    },
    async reload() {
      this.shops = await util.callCloudFunction('getShop');
      this.shopNameList = this.shops.map((obj) => obj.name);
      // this.users = await user.getByRoll(CONF.ROLL_VALUE.MEMBER);
      this.shiftRangeItems = util.timeList(0, 23);
    },
    shopIdToShopName(ids) {
      const names = ids.map(id => this.shops.find(v => v.id === id).name);
      return names.join(',  ');
    },
    shopNameToShopId() {
      this.member.shops = this.member.shopNames.map(name => this.shops.find(v => v.name === name).id);
    },
    shiftSort() {
      if (this.member.weekdayShift) {
        this.member.weekdayShift = this.member.weekdayShift.sort();
      }

      if (this.member.holidayShift) {
        this.member.holidayShift = this.member.holidayShift.sort();
      }
    },
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.member = Object.assign({}, item);

      const names = this.member.shops.map(id => this.shops.find(v => v.id === id).name);
      this.member.shopNames = names;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.member = {};
        this.editedIndex = -1;
        this.$refs.form.resetValidation();
      });
    },
    async save() {
      this.loading = true;
      if (!this.$refs.form.validate()) {
        this.$store.commit('snackbar/open', { text: '入力内容に誤りがあります。', color: 'error' });
        this.loading = false;
        return;
      }
      // this.shopNameToShopId();
      this.shiftSort();
      // this.selectInstructor.updateBy = this.userInfo.uid;
      if (this.action === 'add') {
        this.member.membership = '入会中';
        this.member.roll = 'member';
        await util.callCloudFunction('addUser', this.member).then(() => {
          this.$store.commit('snackbar/open', { text: '会員は正常に登録されました。', color: 'success' });
          this.$emit('reload');
          this.dialog = false;
        }).catch((res) => {
          const msg = !res ? '会員の登録に失敗しました。' : res;
          this.$store.commit('snackbar/open', { text: msg, color: 'error' });
        });
      } else {
        await util.callCloudFunction('updateUser', this.member).then(() => {
          this.$store.commit('snackbar/open', { text: '会員は正常に更新されました。', color: 'success' });
          this.$emit('reload');
          this.dialog = false;
        }).catch(() => {
          this.$store.commit('snackbar/open', { text: '会員の更新に失敗しました。', color: 'error' });
        });
      }
      this.loading = false;
    },
  },
};
</script>
<style>
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.v-messages__message {
  line-height: 13px !important;
}
</style>
