<template>
  <div class='pa-6'>
    <v-sheet tile height='60' class='d-flex'>
      <h2>会員管理</h2>
      <v-spacer></v-spacer>
      <v-text-field
        v-model='search'
        prepend-inner-icon='mdi-magnify'
        hide-details
        label='会費ペイID, 会員名, メールアドレス で検索ができます。'
      ></v-text-field>
    </v-sheet>
    <v-sheet tile height='60' class='d-flex'>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        @click="add"
      >
        <v-icon>mdi-plus</v-icon>
        会員を追加
      </v-btn>
    </v-sheet>
    <v-data-table
      :search='search'
      :headers='headers'
      :items='members'
      sort-by='payid'
      :items-per-page='10'
      :loading='loading'
      loading-text='読み込み中です'
      no-data-text='対象のデータがありません'
    >
      <template v-slot:item.name='{ item }'>
      {{ item.name }}
        <p
          class='mb-0 text--secondary v-messages'
          style='line-height: 1.5;'
        >
        ({{ item.nameKana }})
        </p>
      </template>
      <template v-slot:item.shops='{ item }'>
      {{ shopIdToShopName(item.shops) }}
      </template>
      <template v-slot:item.status='{ item }'>
      {{ item.status ? '有効' : '無効' }}
      </template>
      <template v-slot:item.lefty="{ item }">
      {{ item.lefty?'●':'-' }}
      </template>
      <template v-slot:item.actions='{ item }'>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click="karteOpen(item)"
            >
              <v-icon>mdi-book</v-icon>
            </v-btn>
          </template>
          <span>カルテ表示</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click="edit(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>編集</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <member-dialog v-model="dialog" @reload="reload" :action="action" :selectMember="selectMember"></member-dialog>
    <karte-dialog v-model="karteDialog" :user="selectMember"></karte-dialog>
  </div>
</template>

<script>
import util from '../util';
import MemberDialog from '../components/MemberDialog';
import KarteDialog from '../components/KarteDialog';

export default {
  components: {
    MemberDialog,
    KarteDialog,
  },
  data: () => ({
    dialog: false,
    karteDialog: false,
    headers: [
      { text: '会費ペイID', value: 'payid' },
      { text: '会員名', value: 'name' },
      { text: 'メールアドレス', value: 'email' },
      { text: '所属店舗', value: 'shops' },
      { text: 'レフティ', value: 'lefty' },
      { text: '入会状況', value: 'membership' },
      { text: 'ステータス', value: 'status' },
      { text: '操作', sortable: false, value: 'actions' },
    ],
    members: [],
    shops: [],
    shopNameList: [],
    selectMember: {},
    action: '',
    loading: false,
    search: '',
  }),

  mounted() {
    this.reload();
  },

  methods: {
    karteOpen(member) {
      this.selectMember = member;
      this.karteDialog = true;
    },
    add() {
      this.action = 'add';
      this.selectMember = {};
      this.dialog = true;
    },
    edit(member) {
      this.action = 'edit';
      this.selectMember = member;
      this.dialog = true;
    },
    async reload() {
      this.loading = true;
      this.shops = await util.callCloudFunction('getShop');
      this.shopNameList = this.shops.map((obj) => obj.name);
      this.members = await util.callCloudFunction('getUser', { roll: 'member', status: 'all' });
      this.loading = false;
    },
    shopIdToShopName(ids) {
      const names = ids.map(id => this.shops.find(v => v.id === id).name);
      return names.join(',  ');
    },
    toAbbreviate(data) {
      return `${data[0]} ~ ${data[data.length - 1]}`;
    },
  },
};
</script>
<style>
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.v-data-table tr:hover td {
    background-color: #eee;
}
.v-label {
  font-size: 14px;
}
</style>
